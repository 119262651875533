
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { scriptTagsByConfig } from '~/constants/scriptTags'
import { metaTags } from '~/constants/metaTags'

interface Data {}

interface Methods {}

interface Computed {}

interface Props {}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  head() {
    const env = process.env.NODE_ENV || 'development'
    const scriptTags = scriptTagsByConfig({
      env: env as 'production' | 'development',
      useCase: 'index',
    })
    return {
      title: 'ページが見つかりませんでした | 受講者数No.1のテックアカデミー',
      meta: [
        ...metaTags(
          {
            ogUrl: '/errors/404',
            noIndexNofollow: true,
          },
          process.env.host as string
        ),
      ],
      script: scriptTags!.script,
      __dangerouslyDisableSanitizersByTagID: scriptTags!
        .__dangerouslyDisableSanitizersByTagID,
    }
  },
  layout: 'Te/TeErrors',
}

export default Vue.extend(options)
